import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import Homepage3 from '../HomePage3'
import Homepage4 from '../HomePage4'
import AboutPage from '../AboutPage'
import ServicePage from '../ServicePage'
import ShopPage from '../ShopPage'
import ShopSinglePage from '../ShopSinglePage'
import EventPage from '../EventPage'
import EventPageSingle from '../EventPageSingle'
import DonatePage from '../DonatePage'
import BlogPage from '../BlogPage'
import About1Page from "../About1Page";
import About2Page from "../About2Page";
import About3Page from "../About3Page";
import About4Page from "../About4Page";
import About5Page from "../About5Page";
import About6Page from "../About6Page";
import BlogPageLeft from '../BlogPageLeft'
import BlogPageFullwidth from '../BlogPageFullwidth'
import BlogDetails from '../BlogDetails' 
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide' 
import BlogDetailsFull from '../BlogDetailsFull'
import ErrorPage from '../ErrorPage'  
import ContactPage from '../ContactPage' 
import LoginPage from '../LoginPage' 
import SignUpPage from '../SignUpPage' 
import ForgotPassword from '../ForgotPassword'
import ServiceQuranMemorizationPage from "../ServiceQuranMemorizationPage";
import ServiceCleanWaterBoresPage from "../ServiceCleanWaterBoresPage";
import ServiceMasjidDevelopmentPage from "../ServiceMasjidDevelopmentPage";
import ServiceFeedingTheNeedyPage from "../ServiceFeedingTheNeedyPage";
import ServiceChildSponsorsPage from "../ServiceChildSponsorsPage";
import ServiceAnimalSacrificePage from "../ServiceAnimalSacrificePage";


const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage2}/>
            <Route path='/home' component={Homepage} />
            <Route path='/home2' component={Homepage2} />
            <Route path='/home3' component={Homepage3} />
            <Route path='/home4' component={Homepage4} />
            <Route path='/about' component={AboutPage} />
            <Route path='/about-1' component={About1Page} />
            <Route path='/about-2' component={About2Page} />
            <Route path='/about-3' component={About3Page} />
            <Route path='/about-4' component={About4Page} />
            <Route path='/about-5' component={About5Page} />
            <Route path='/about-6' component={About6Page} />
            <Route path='/service' component={ServicePage} />
            <Route path='/shop' component={ShopPage} />
            <Route path='/shop-single' component={ShopSinglePage} />
            <Route path='/service-quran-memorization' component={ServiceQuranMemorizationPage}/>
            <Route path='/service-clean-water-bores' component={ServiceCleanWaterBoresPage}/>
            <Route path='/service-masjid-development' component={ServiceMasjidDevelopmentPage}/>
            <Route path='/service-feeding-the-needy' component={ServiceFeedingTheNeedyPage}/>
            <Route path='/service-child-sponsors' component={ServiceChildSponsorsPage}/>
            <Route path='/service-animal-sacrifice' component={ServiceAnimalSacrificePage}/>
            <Route path='/event' component={EventPage}/>
            <Route path='/event-single' component={EventPageSingle}/>
            <Route path='/donate' component={DonatePage}/>
            {/* <Route path='/blog' component={BlogPage}/> 
            <Route path='/blog-left' component={BlogPageLeft}/>
            <Route path='/blog-fullwidth' component={BlogPageFullwidth}/> 
            <Route path='/blog-details' component={BlogDetails}/>
            <Route path='/blog-details-left' component={BlogDetailsLeftSiide}/>
            <Route path='/blog-details-fullwidth' component={BlogDetailsFull}/>
            <Route path='/404' component={ErrorPage}/> */}
            <Route path='/contact' component={ContactPage}/>
            <Route path='/login' component={LoginPage}/>
            <Route path='/signup' component={SignUpPage}/>
            <Route path='/forgot-password' component={ForgotPassword}/>
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
