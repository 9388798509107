import React from 'react'
import {Link} from 'react-router-dom'
import VideoModal from '../ModalVideo'
import qasim from '../../images/qasim.png'
import './style.css'

const About1 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="wpo-about-img-3">
                                <img src={qasim} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <span>Hazrat Pir Qasim Sadiq</span>
                                    <h2>Hazrat Pir Qasim Sadiq</h2>
                                </div>
                                <p>Hazrat Khawaja Muhammad Qasim Sadiq was a visionary Sufi master who devoted his life to spreading the true teachings of Islam, focusing on peace, spiritual guidance, and deep love for Allah, Prophet Muhammad (PBUH), and the revered Panjtan Pak. Born in 1845, he lost his father at six months and was raised by his devout mother, who provided him with his initial education. As he matured, Khawaja Qasim sought knowledge from esteemed scholars but yearned for deeper spiritual enlightenment. His journey led him to Kashmir, where he found his spiritual guide, Hazrat Khawaja Nazam Udin Kiyanvi. Under his guidance, Khawaja Qasim embraced the spiritual path and was entrusted with the mission to spread the true teachings of Islam.</p>

                                <p>Following his master’s advice, Khawaja Qasim moved to a remote jungle near Murree, where he established a center of spiritual enlightenment. Through Zikr (remembrance of Allah) and Quranic teachings, he transformed the area into a sanctuary for those seeking spiritual healing and guidance. His teachings emphasized patience, love for Allah, and protection against negative influences, helping people overcome emotional and spiritual challenges. Many followers who visited him found solace, healing, and a deeper connection with Allah through his wisdom and spiritual practices.</p>

                                <p>Khawaja Qasim's approach to spiritual guidance was marked by his humility and dedication to his followers. He believed that a true master must offer genuine enlightenment, and his teachings emphasized constant remembrance of Allah, even in daily activities. He warned against the distractions of worldly life, encouraging people to focus on their devotion to Allah and the teachings of the Prophet. His sessions of Zikr were not only acts of worship but powerful tools for spiritual purification, guiding his followers toward inner and outer purity.</p>

                                <p>Throughout his life, Khawaja Qasim faced many challenges, including opposition from non-Muslim communities. Despite attempts to hinder his mission, his unwavering devotion to Allah and his compassionate nature won over many hearts. His wisdom, humility, and spiritual power gained respect even from those who initially opposed him. His contributions to the Pakistan movement and his influence on leaders and communities across the subcontinent were profound, as he worked to unite Muslims and promote peaceful coexistence.</p>

                                <p>Khawaja Qasim's legacy lives on through his followers and the many Khalifas he trained, who continue to spread his teachings globally. His emphasis on good character, devotion to Allah, and service to humanity continues to inspire millions of people in Pakistan, Kashmir, and beyond. His famous sayings, such as “Do everything for the sake of Allah,” reflect his deep faith and commitment to the betterment of society through the teachings of Islam and Sufism.</p>
                                <div className="btns">
                                    <Link onClick={ClickHandler} to="/about" className="theme-btn" tabIndex="0">Discover More</Link>
                                    <ul>
                                        <li className="video-holder">
                                            <VideoModal/>
                                        </li>
                                        <li className="video-text">
                                            Watch Our Video
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About1;
