import React from 'react'
import { Link } from 'react-router-dom'
import sacrifice from '../../images//service/sacrifice1.jpg'
import service2 from '../../images/service-detail2.jpg'
import './style.css'

const ServiceAnimalSacrifice = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-md-12 col-12">
                        <div className="service-single-content">
                            <div className="service-single-img">
                                <img src={sacrifice} alt=""/>
                            </div>
                            <h2>Animal Sacrifice</h2>
                            <p>Mohra Sharif, a charitable organization based in Rawalpindi, actively participates in the tradition of animal sacrifice during the Islamic festival of Eid ul Azha. Each year, the organization carries out this ritual across Pakistan, including in its home base of Mohra Sharif as well as remote areas like Azad Kashmir, Hafizabad, and interior Sindh. The animals sacrificed during this occasion are carefully chosen, and the act of sacrifice is performed with the utmost reverence, adhering to Islamic teachings. Through these efforts, Mohra Sharif ensures that the true spirit of Eid ul Azha, which revolves around charity and compassion, is upheld.</p>
                            <p>The primary purpose of this sacrificial ritual is to provide meat to the needy, who may otherwise not have the means to afford such nourishment. Mohra Sharif takes great care to distribute the meat from these sacrifices to poor and deserving families, ensuring that everyone can partake in the festivities of Eid. By reaching out to remote and underprivileged communities, the organization brings joy and a sense of inclusion to those who might feel marginalized. This initiative also helps foster a sense of unity and brotherhood within these communities.</p>
                            <div className="service-single-tb">
                                <div className="service-single-inner">
                                    {/* <span><img src={service2} alt=""/></span> */}
                                    <div className="service-single-tb-text">
                                        <p>The logistics involved in organizing such a large-scale animal sacrifice program are considerable, but Mohra Sharif's dedicated volunteers and staff work tirelessly to manage the entire process. From sourcing animals and ensuring their welfare to overseeing the sacrifice and distribution, every step is handled with meticulous planning and care. The organization’s efforts extend beyond just the act of sacrifice; they focus on spreading happiness and goodwill, ensuring that the joy of Eid ul Azha reaches as many people as possible, particularly those living in remote and difficult-to-reach areas</p>
                                        <p>Support from the community and donors is crucial to sustaining this annual initiative. Mohra Sharif continually seeks the involvement of generous individuals who can contribute to the purchase of sacrificial animals or volunteer their time. By supporting Mohra Sharif's animal sacrifice program, donors and volunteers are directly impacting the lives of countless individuals, providing them not just with food but also with the warmth and joy that come from knowing they are cared for and remembered during this sacred festival.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="service-single-tb-content">
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.</p>
                            </div> */}
                        </div>
                        <div className="prv-nx">
                            <div className="pre-btn"><Link onClick={ClickHandler} to="/service-child-sponsors">Previous</Link></div>
                            <div className="nex-btn"><Link onClick={ClickHandler} to="/service-quran-memorization">Next</Link></div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-12 col-12">
                        <div className="service-sidebar">
                            <div className="widget service-list-widget">
                                <h3>All Services</h3>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/service-quran-memorization">Quran Memorization</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-clean-water-bores">Clean Water Bores</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-masjid-development">Masjid Development</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-feeding-the-needy">Feeding The Needy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-child-sponsors">Child Sponsors</Link></li>
                                    <li className="current"><Link onClick={ClickHandler} to="/service-animal-sacrifice">Animal Sacrifice</Link></li>
                                </ul>
                            </div>
                            <div className="widget contact-widget">
                                <div>
                                    <h4>Request a Call Back</h4>
                                    <h2>(+92) 300-5264409</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceAnimalSacrifice;
