import React from 'react'
import { Link } from 'react-router-dom'
import masjid from '../../images/service/masjid1.jpg'
import './style.css'

const ServiceMasjidDevelopment = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-md-12 col-12">
                        <div className="service-single-content">
                            <div className="service-single-img">
                                <img src={masjid} alt=""/>
                            </div>
                            <h2>Masjid Development</h2>
                            <p>Mohra Sharif, a distinguished charity organization based in Rawalpindi, Pakistan, has been deeply involved in the development and renovation of Masjids (mosques) across the country. Their commitment to religious and educational growth has led them to establish dozens of mosques in various regions, providing not only places of worship but also centers for learning. These mosques serve as vital community hubs where children and adults alike can gain religious knowledge, participate in community events, and find spiritual guidance.</p>
                            <p>The mosques developed by Mohra Sharif are more than just places for daily prayers; they are comprehensive community centers that offer educational programs for children. In these mosques, children are taught the Quran and Hadith, gaining a deep understanding of Islamic teachings and values. Alongside religious education, many of these mosques also provide basic secular education, helping children develop literacy and numeracy skills. This holistic approach ensures that the young generation is well-rounded, rooted in their religious identity, and prepared for the future.</p>
                            <div className="service-single-tb">
                                <div className="service-single-inner">
                                    {/* <span><img src={service2} alt=""/></span> */}
                                    <div className="service-single-tb-text">
                                        <p>In addition to catering to children's educational needs, the mosques built by Mohra Sharif serve as platforms for community development. These mosques host various programs, including adult education classes, health awareness seminars, and community meetings. By providing a space where community members can gather, discuss, and address local issues, Mohra Sharif fosters a sense of unity and collective responsibility. This sense of community support is crucial, especially in rural or underserved areas, where access to such resources might otherwise be limited.</p>
                                        <p>The impact of Mohra Sharif’s mosque development initiatives extends beyond the physical structures. These mosques are instrumental in preserving and promoting Islamic culture and values. They play a crucial role in social cohesion, offering a place where people of all ages can come together, pray, learn, and support one another. As Mohra Sharif continues to develop and support mosques across Pakistan, they remain dedicated to nurturing the spiritual and educational needs of communities, thereby contributing to the holistic development of society.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="service-single-tb-content">
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.</p>
                            </div> */}
                        </div>
                        <div className="prv-nx">
                            <div className="pre-btn"><Link onClick={ClickHandler} to="/service-clean-water-bores">Previous</Link></div>
                            <div className="nex-btn"><Link onClick={ClickHandler} to="/service-feeding-the-needy">Next</Link></div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-12 col-12">
                        <div className="service-sidebar">
                            <div className="widget service-list-widget">
                                <h3>All Services</h3>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/service-quran-memorization">Quran Memorization</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-clean-water-bores">Clean Water Bores</Link></li>
                                    <li className="current"><Link onClick={ClickHandler} to="/service-masjid-development">Masjid Development</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-feeding-the-needy">Feeding The Needy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-child-sponsors">Child Sponsors</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-animal-sacrifice">Animal Sacrifice</Link></li>
                                </ul>
                            </div>
                            <div className="widget contact-widget">
                                <div>
                                    <h4>Request a Call Back</h4>
                                    <h2>(+92) 300-5264409</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceMasjidDevelopment;
