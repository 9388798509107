import React from 'react'
import { Link } from 'react-router-dom'
import quran from '../../images/service/quran1.jpg'
import './style.css'

const ServiceCleanWaterBores = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-md-12 col-12">
                        <div className="service-single-content">
                            <div className="service-single-img">
                                <img src={quran} alt=""/>
                            </div>
                            <h2>Quran Memorization</h2>
                            <p>Mohra Sharif, located in Rawalpindi, Pakistan, is a renowned charity organization dedicated to providing free Quran memorization courses. Rooted in a rich history of Islamic scholarship and spirituality, Mohra Sharif has become a center of religious education, attracting students of all ages who wish to deepen their understanding of the Quran. This noble initiative ensures that financial barriers do not hinder individuals from accessing quality Quranic education, promoting spiritual growth and Islamic values within the community.</p>
                            <p>The free Quran memorization program offered by Mohra Sharif is accessible to everyone, including children, adults, and the elderly. The structured curriculum covers essential aspects such as learning the Arabic language, correct pronunciation (Tajweed), and the meanings of Quranic verses. With the guidance of experienced and qualified teachers, students are provided with personalized support, ensuring they accurately memorize and understand the Holy Quran. Flexible class timings accommodate different schedules, making it easier for people from various walks of life to participate in the program.</p>
                            <div className="service-single-tb">
                                <div className="service-single-inner">
                                    {/* <span><img src={service2} alt=""/></span> */}
                                    <div className="service-single-tb-text">
                                        <p>Beyond just memorization, the program at Mohra Sharif emphasizes the holistic development of its students. It encourages the embodiment of Quranic teachings, fostering moral and ethical values such as compassion, empathy, and kindness. This approach not only strengthens the students' connection to their faith but also inspires them to become positive contributors to their communities. By instilling these principles, Mohra Sharif helps shape individuals who lead lives guided by the values of Islam, promoting peace and harmony.</p>
                                        <p>The impact of Mohra Sharif’s initiatives extends beyond its students to the broader community. The organization plays a pivotal role in promoting Islamic education, empowering individuals through knowledge, and fostering unity among community members. Moreover, Mohra Sharif engages in various charitable activities, including food distribution and financial assistance to underprivileged families, reflecting its commitment to serving humanity. As it continues to expand its educational programs and outreach efforts, Mohra Sharif remains a beacon of light, spreading the timeless teachings of Islam and making a profound difference in the lives of many.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="service-single-tb-content">
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.</p>
                            </div> */}
                        </div>
                        <div className="prv-nx">
                            <div className="pre-btn"><Link onClick={ClickHandler} to="/service-animal-sacrifice">Previous</Link></div>
                            <div className="nex-btn"><Link onClick={ClickHandler} to="/service-clean-water-bores">Next</Link></div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-12 col-12">
                        <div className="service-sidebar">
                            <div className="widget service-list-widget">
                                <h3>All Services</h3>
                                <ul>
                                    <li className="current"><Link onClick={ClickHandler} to="/service-quran-memorization">Quran Memorization</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-clean-water-bores">Clean Water Bores</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-masjid-development">Masjid Development</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-feeding-the-needy">Feeding The Needy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-child-sponsors">Child Sponsors</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-animal-sacrifice">Animal Sacrifice</Link></li>
                                </ul>
                            </div>
                            <div className="widget contact-widget">
                                <div>
                                    <h4>Request a Call Back</h4>
                                    <h2>(+92) 300-5264409</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceCleanWaterBores;
