import React from 'react'
import {Link} from 'react-router-dom'
import tmr1 from '../../images/prayer-shape/2.png'
import tmr2 from '../../images/prayer-shape/1.png'
import farooq from '../../images/farooq.png'
import VideoModal from '../ModalVideo'
import './style.css'

const About4 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="wpo-about-img-3">
                                <img src={farooq} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <span>Hazrat Pir Farooq Gul Badshah</span>
                                    <h2>Hazrat Pir Farooq Gul Badshah</h2>
                                </div>
                                <p>Pir Muhammad Farooq Gul Badsha, born on February 14, 1947, showcased remarkable academic talent from an early age. He began his education at home before attending Sir Syed Public School in Rawalpindi, later excelling at Military College Jhelum. Following his intermediate studies, he pursued higher education at F.C. College Lahore and earned a Master’s degree in Political Science from Government College Lahore. His tenure at G.C. College was marked by academic excellence and leadership, as he served as the president of the Political Science Department, receiving accolades for his contributions.</p>

                                <p>Beyond academia, Pir Farooq Gul Badsha was deeply committed to disseminating Islamic teachings. He delivered over 500 lectures covering various topics, such as the Quran, Sufism, and the significance of lawful earnings. He emphasized that earning a halal income was vital for spiritual clarity, frequently reminding his followers that the righteousness of one's income directly impacts the acceptance of prayers. Following his father’s wishes, he chose not to pursue a bureaucratic career after passing the CSS exams, instead entering the construction industry and eventually founding his own company, "Pak Trade Affiliation Pvt. Ltd."</p>

                                <p>His dedication and hard work in construction earned him respect across the industry. He personally oversaw projects under extreme weather conditions, demonstrating a relentless commitment to quality and timely completion. In response to growing corruption in Pakistan’s construction sector, he transitioned to real estate and agricultural investments, supporting local farmers and contributing to the economy. A significant portion of his earnings went towards philanthropic efforts, providing aid to the poor, constructing facilities for visitors, and guiding his extended family in education and values.</p>

                                <p>As the successor of the Darbar Mohra Sharif, Pir Farooq Gul Badsha held a vital position, receiving visits from diplomats, politicians, and community members seeking guidance. His teachings centered on the principles of Sufism and emphasized the importance of education, ethical conduct, and the teachings of the Quran. His outreach extended to practical solutions for personal development, reinforcing his followers' spiritual growth and understanding of Islam.</p>

                                <p>His legacy included significant humanitarian efforts, particularly following the 2005 earthquake in Kashmir and KPK, where he led relief initiatives that provided essential aid to affected families. His teachings and example continue to inspire many, as he encouraged followers to live with integrity and service to others. His passing marked the end of an era, but his profound influence endures, leaving a lasting impact on his community and the teachings of Sufism.</p>
                                <div className="btns">
                                    <Link onClick={ClickHandler} to="/about4" className="theme-btn" tabIndex="0">Discover More</Link>
                                    <ul>
                                        <li className="video-holder">
                                            <VideoModal/>
                                        </li>
                                        <li className="video-text">
                                            Watch Our Video
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="timer-section">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="timer-text">
                                <h2>Prayer Times</h2>
                                <p>Prayer times in Rawalpindi Pakistan</p>
                                <span>Mon 15 Jan, 2021</span>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="timer-num">
                                <ul>
                                    <li>Fajr<span>05:47</span></li>
                                    <li>Sunrize<span>07:05</span></li>
                                    <li>Dhuhr<span>12:34</span></li>
                                    <li>Asr<span>15:35</span></li>
                                    <li>Maghrib<span>17:58</span></li>
                                    <li>Isha'a<span>19:15</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="timer-shape1">
                        <img src={tmr1} alt="" />
                    </div>
                    <div className="timer-shape2">
                        <img src={tmr2} alt="" />
                    </div>
                    <div className="timer-shape3">
                        <img src={tmr3} alt="" />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default About4;