import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import SidebarWrap from '../SidebarWrap'
import simg from '../../images/event.jpg'




import './style.css'

const EventSingle = (props) => {

    const SubmitHandler = (e) =>{
        e.preventDefault()
    }

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

        return (
            <div className="wpo-event-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-8">
                            <div className="wpo-event-item">
                                <div className="wpo-event-img">
                                    <img src={simg} alt=""/>
                                    <div className="thumb-text">
                                        <span>2</span>
                                        <span>June</span>
                                    </div>
                                </div>
                                <div className="wpo-event-details-text">
                                    <h2>Annual Milad Conference</h2>
                                    <p>The annual Milad Conference held at Mohra Sharif in Rawalpindi is a significant religious gathering that brings together thousands of devotees from across Pakistan to celebrate the birth of the Prophet Muhammad (PBUH). This spiritually enriching event features recitations of the Quran, naats (devotional poetry), and sermons by renowned scholars who share insights into the life and teachings of the Prophet. The conference is a time for reflection, prayer, and community bonding, with attendees expressing their love and devotion to the Prophet through various forms of worship and celebration. Organized with great devotion by the Mohra Sharif administration, the event fosters a deep sense of unity, faith, and religious commitment among participants.</p>
                                </div>
                                <div className="wpo-event-details-wrap">
                                    <div className="wpo-event-details-tab">
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => { toggle('1'); }}
                                                >
                                                    Event Schedule
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '2' })}
                                                    onClick={() => { toggle('2'); }}
                                                >
                                                Map Location
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '3' })}
                                                        onClick={() => { toggle('3'); }}
                                                    >
                                                    Contact Us
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <div className="wpo-event-details-content">
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1" id="Schedule">
                                                <p>The conference commences with an opening ceremony, where attendees gather to recite the Quran and offer special prayers. This marks the beginning of a day filled with spiritual activities and is a time for participants to reflect on the significance of the Prophet's teachings.</p>
                                                <ul>
                                                <li>Opening Ceremony: Quran recitation and special prayers.</li>
                                                <li>Keynote Addresses: Speeches by renowned Islamic scholars.</li>
                                                <li>Naat and Qawwali Performances: Devotional poetry and music.</li>
                                                <li>Closing Remarks and Dua: Final words and collective prayer.</li>
                                                </ul>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <div className="contact-map enent-map">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53172.20158764615!2d73.00318645972925!3d33.598490365409326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df94e3538dbb3b%3A0x415e253f09ce689!2sDarbar-e-Aliya%20Mohra%20Sharif!5e0!3m2!1sen!2s!4v1725202499802!5m2!1sen!2s"></iframe>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <div className="event-contact">
                                                    <div className="wpo-donations-details">
                                                        <form onSubmit={SubmitHandler} id="contact-form">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                                                    <input type="text" className="form-control" name="name" id="name" placeholder="Your Name*"/>
                                                                </div>
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group clearfix">
                                                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email"/>
                                                                </div>
                                                                <div className="col-lg-12 col-12 form-group">
                                                                    <textarea className="form-control" name="note" id="note" placeholder="Massage"></textarea>
                                                                </div>
                                                                <div className="submit-area col-lg-12 col-12">
                                                                    <button type="submit" className="theme-btn submit-btn">Submit Now</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SidebarWrap/>

                    </div>
                </div>
            </div>

            );
    }
    
    export default EventSingle;
          
          
          
          
