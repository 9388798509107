import React from 'react'
import {Link}  from 'react-router-dom'
import './style.css'

const HeaderTopbar2 = () => {
    return(	
        <div className="header-topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-md-6 col-sm-7 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="fi ti-location-pin"></i>Mohra Sharif Rawalpindi</li>
                                <li><i className="fi flaticon-envelope"></i> mohrasharifrawalpindi@gmail.com</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-md-6 col-sm-5 col-12">
                        <div className="contact-info">
                            <ul>
                                {/* <li><Link to="/home2"><i className="ti-facebook"></i></Link></li>
                                <li><Link to="/home2"><i className="ti-twitter-alt"></i></Link></li>
                                <li><Link to="/home2"><i className="ti-instagram"></i></Link></li>
                                <li><Link to="/home2"><i className="ti-google"></i></Link></li> */}
                                <li><a href="https://www.youtube.com/@mohrashareef1" target="_blank" rel="noopener noreferrer"><i className="ti-youtube"></i></a></li>
                                <li><a href="https://www.facebook.com/pirmujtabafarooqgulbadshah?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"><i className="ti-facebook"></i></a></li>
                                <li><a href="https://www.twitter.com/pirmujtabafaroq" target="_blank" rel="noopener noreferrer"><i className="ti-twitter-alt"></i></a></li>
                                <li><a href="https://www.instagram.com/pirmujtabafarooqgul?igsh=OHRqbWpocWM3d3hv" target="_blank" rel="noopener noreferrer"><i className="ti-instagram"></i></a></li>
                                {/* <li><Link className="theme-btn-s2" to="/donate">Make Donation </Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar2;