import React from 'react'
import { Link } from 'react-router-dom'
import bore from '../../images/service/bore1.jpg'
import './style.css'

const ServiceQuranMemorization = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-md-12 col-12">
                        <div className="service-single-content">
                            <div className="service-single-img">
                                <img src={bore} alt=""/>
                            </div>
                            <h2>Clean Water Bores</h2>
                            <p>Mohra Sharif, a prominent charity organization based in Rawalpindi, Pakistan, has significantly contributed to providing clean water access through the installation of water bores across the country. Understanding the critical need for clean and safe drinking water, especially in rural and underprivileged areas, Mohra Sharif has taken it upon itself to address this fundamental issue. Their efforts have brought relief to countless communities, ensuring that people have access to a reliable source of clean water, essential for health, hygiene, and daily living</p>
                            <p>The organization's water bore projects are strategically implemented in regions that suffer from water scarcity or where existing water sources are contaminated. By working closely with local communities and conducting thorough assessments, Mohra Sharif ensures that each water bore is installed where it is most needed. These projects not only provide immediate relief by supplying clean drinking water but also help prevent waterborne diseases that are prevalent in areas with inadequate water sanitation. The initiative has directly improved the health and well-being of thousands of families across Pakistan.</p>
                            <div className="service-single-tb">
                                <div className="service-single-inner">
                                    {/* <span><img src={service2} alt=""/></span> */}
                                    <div className="service-single-tb-text">
                                        <p>Beyond simply drilling wells, Mohra Sharif's approach involves sustainable water management and community education. They engage local residents in the maintenance of water bores and provide training on the importance of water conservation and hygiene practices. This educational component is crucial in fostering a sense of ownership among the beneficiaries and ensuring the long-term success of the water projects. By empowering communities to manage their water resources effectively, Mohra Sharif promotes sustainability and helps to secure a healthier future for these regions.</p>
                                        <p>The impact of Mohra Sharif's clean water initiatives extends far beyond the immediate provision of water. Access to clean water significantly improves the quality of life by reducing the time and effort required to collect water, allowing children to attend school and adults to engage in productive activities. It also plays a vital role in supporting local economies and agricultural practices. As Mohra Sharif continues to expand its clean water projects across Pakistan, it remains dedicated to improving the lives of countless individuals, reflecting its commitment to humanitarian service and the well-being of communities nationwide.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="service-single-tb-content">
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.</p>
                            </div> */}
                        </div>
                        <div className="prv-nx">
                            <div className="pre-btn"><Link onClick={ClickHandler} to="/service-quran-memorizatio">Previous</Link></div>
                            <div className="nex-btn"><Link onClick={ClickHandler} to="/service-masjid-development">Next</Link></div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-12 col-12">
                        <div className="service-sidebar">
                            <div className="widget service-list-widget">
                                <h3>All Services</h3>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/service-quran-memorization">Quran Memorization</Link></li>
                                    <li className="current"><Link onClick={ClickHandler} to="/service-clean-water-bores">Clean Water Bores</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-masjid-development">Masjid Development</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-feeding-the-needy">Feeding The Needy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-child-sponsors">Child Sponsors</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-animal-sacrifice">Animal Sacrifice</Link></li>
                                </ul>
                            </div>
                            <div className="widget contact-widget">
                                <div>
                                    <h4>Request a Call Back</h4>
                                    <h2>(+92) 300-5264409</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceQuranMemorization;
