import React from 'react'
import { Link } from 'react-router-dom'
import feeding from '../../images/service/feeding1.jpg'
import './style.css'

const ServiceFeedingTheNeedy = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-md-12 col-12">
                        <div className="service-single-content">
                            <div className="service-single-img">
                                <img src={feeding} alt=""/>
                            </div>
                            <h2>Feeding The Needy</h2>
                            <p>Mohra Sharif, a renowned charity organization in Rawalpindi, has been making significant strides in feeding the needy across various locations, including Rawalpindi, Islamabad, and other surrounding areas. Their efforts ensure that individuals who face food insecurity receive nutritious meals three times a day, every day. By addressing the basic human need for food, Mohra Sharif plays a vital role in supporting the well-being of countless individuals and families in need.</p>
                            <p>At the heart of Mohra Sharif’s feeding initiative is a commitment to dignity and respect. They have established multiple feeding centers where people can come and receive meals without any discrimination or stigma. These centers operate daily, providing breakfast, lunch, and dinner to those who are struggling to make ends meet. The food provided is not only nutritious but also culturally appropriate, ensuring that recipients can enjoy meals that are familiar and comforting. This effort is a testament to the organization’s dedication to enhancing the quality of life for the vulnerable segments of society</p>
                            <div className="service-single-tb">
                                <div className="service-single-inner">
                                    {/* <span><img src={service2} alt=""/></span> */}
                                    <div className="service-single-tb-text">
                                        <p>The feeding programs by Mohra Sharif also extend beyond static centers. They have mobile food distribution services that reach out to people who may not be able to come to the feeding centers, such as the elderly, disabled, and those living in remote areas. This proactive approach ensures that the organization’s support reaches every corner of the community, providing a safety net for individuals and families facing food scarcity. By addressing hunger, Mohra Sharif not only meets immediate needs but also contributes to the overall health and stability of the community.</p>
                                        <p>Beyond just providing food, Mohra Sharif’s feeding initiatives foster a sense of community and solidarity. These meal programs bring together people from different walks of life, creating opportunities for social interaction and support. For many, these feeding centers become more than just a place to receive food; they become places of hope, connection, and kindness. Through their unwavering commitment to feeding the needy, Mohra Sharif exemplifies compassion in action, making a profound impact on the lives of those they serve and reinforcing the values of generosity and empathy within the community.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="service-single-tb-content">
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.</p>
                            </div> */}
                        </div>
                        <div className="prv-nx">
                            <div className="pre-btn"><Link onClick={ClickHandler} to="/service-masjid-development">Previous</Link></div>
                            <div className="nex-btn"><Link onClick={ClickHandler} to="/service-child-sponsors">Next</Link></div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-12 col-12">
                        <div className="service-sidebar">
                            <div className="widget service-list-widget">
                                <h3>All Services</h3>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/service-quran-memorization">Quran Memorization</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-clean-water-bores">Clean Water Bores</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-masjid-development">Masjid Development</Link></li>
                                    <li className="current"><Link onClick={ClickHandler} to="/service-feeding-the-needy">Feeding The Needy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-child-sponsors">Child Sponsors</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-animal-sacrifice">Animal Sacrifice</Link></li>
                                </ul>
                            </div>
                            <div className="widget contact-widget">
                                <div>
                                    <h4>Request a Call Back</h4>
                                    <h2>(+92) 300-5264409</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceFeedingTheNeedy;
