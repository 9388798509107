import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import About4 from '../../components/about-4'
import Course from '../../components/Course'
import EventSection from '../../components/event'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import abimg from '../../images/about2.png'
import evn1 from '../../images/event/img-3.jpg'
import evn2 from '../../images/event/img-4.jpg'


const About4Page =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Hazrat Pir Farooq Gul Badshah'} pagesub={'Hazrat Pir Farooq Gul Badshah'}/>
            <About4 aboutImg={abimg}/>
            <Course/>
            <EventSection eventImg1={evn1} eventImg2={evn2}  EventClass={'wpo-event-area-2'}/>
            <Footer footerClass={'wpo-ne-footer-2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default About4Page;
