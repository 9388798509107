import React from 'react'
import { Link } from 'react-router-dom'
import service1 from '../../images/service/quran.jpg'
import service2 from '../../images/service/water.jpg'
import service3 from '../../images/service/masjid.jpg'
import service4 from '../../images/service/feeding.jpg'
import service5 from '../../images/service/sponser.jpg'
import service6 from '../../images/service/animal.jpg'
import './style.css'

const Service2 = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
      
    const serviceWrap = [
        {
           simg:service1,
           title:"Quran Memorization",
           link:"/service-quran-memorization"
        },
        {
           simg:service2,
           title:"Clean Water Bores",
           link:"/service-clean-water-bores"
        },
        {
           simg:service3,
           title:"Masjid Development",
           link:"/service-masjid-development"
        },
        {
           simg:service4,
           title:"Feeding The Needy",
           link:"/service-feeding-the-needy"
        },
        {
           simg:service5,
           title:"Child Sponsors",
           link:"/service-child-sponsors"
        },
        {
           simg:service6,
           title:"Animal Sacrifice",
           link:"/service-animal-sacrifice"
        },
    ]

    return(
        <div className={`service-area-2 ${props.serviceClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            <span>What We Offer</span>
                            <h2>Our Populer Services</h2>
                        </div>
                    </div>
                </div>
                <div className="service-wrap">
                    <div className="row">
                        {serviceWrap.map((item, i) => (
                            <div className="col-lg-4 col-md-6 col-sm-12 custom-grid col-12" key={i}>
                                <div className="service-single-item">
                                    <div className="service-single-img">
                                        <img src={item.simg} alt="" />
                                    </div>
                                    <div className="service-text">
                                        <h2><Link onClick={ClickHandler} to={item.link}>{item.title}</Link></h2>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service2;
