import React from 'react'
import {Link} from 'react-router-dom'
import tmr1 from '../../images/prayer-shape/2.png'
import tmr2 from '../../images/prayer-shape/1.png'
import tmr3 from '../../images/prayer-shape/3.png'
import nazir from '../../images/nazir.png'

import VideoModal from '../ModalVideo'
import './style.css'

const About2 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="wpo-about-img-3">
                                <img src={nazir} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <span>Hazrat Pir Nazeer Ahmad</span>
                                    <h2>Hazrat Pir Nazeer Ahmad</h2>
                                </div>
                                <p>Pir Muhammad Nazir Ahmed, born in 1880, was the eldest son of the esteemed Shaykh Khawaja Muhammad Qasim Sadiq. Under his father's tutelage and other prominent scholars, Pir Nazir developed into a learned and visionary Sufi leader. His spiritual journey began early when, at the age of 12, he was blessed by Khawaja Nazam ud din Kiyanvi with a prayer that his descendants would be elevated Auliya (Sufis). Recognizing his son’s spiritual potential, Pir Qasim officially nominated Pir Nazir Ahmed as his successor twice, first in 1912 and later in 1925. Pir Qasim entrusted him with the responsibilities of continuing the Darbar's work, including managing the Langar and treating all people with equal respect.</p>

                                <p>Pir Nazir Ahmed dedicated his life to promoting the unity of the Muslim Ummah through his devotion to Prophet Muhammad (PBUH) and the philosophy of “Nisbat E Rasooli.” His efforts sought to bring together Muslims from different sects and groups under the banner of love for the Prophet. In various conferences, he emphasized that success could only be achieved by becoming true servants of the Prophet and living by the teachings of Islam. His charismatic leadership attracted millions of followers, and his influence extended across diverse regions, uniting Muslims, Christians, and Jews under his guidance for peace and mutual respect.</p>

                                <p>Pir Nazir Ahmed's leadership was not limited to spiritual matters; he was also actively involved in political movements. His growing influence across Punjab, KPK, and Kashmir caught the attention of both local tribal leaders and the British government. Many tribal chiefs and Nawabs pledged allegiance to him, with some even proposing the establishment of a state, Nazirabad, in his honor. Despite the British government's attempts to negotiate with him, Pir Nazir Ahmed refused to compromise on principles rooted in the Quran and Sunnah, leading to his eventual ban from entering the tribal areas. Pir Nazir, however, remained steadfast in his spiritual mission, choosing to focus on guiding his followers rather than pursuing political power.</p>

                                <p>In his teachings, Pir Nazir Ahmed emphasized that faith, remembrance of Allah, and following the Prophet (PBUH) were the solutions to life's challenges. He advocated that spiritual practices like Dhikr could alleviate stress, depression, and anxiety. He encouraged his followers to stay committed to righteous deeds and to strive to become true servants of Allah. His teachings illuminated the hearts of those who sought his guidance, leading them toward spiritual enlightenment and a deeper connection with the divine.</p>

                                <p>Pir Nazir Ahmed's legacy extended to many notable political figures and religious leaders. Prominent personalities like Quaid-e-Azam Muhammad Ali Jinnah, Sikandar Mirza, and Abdul Qayum Khan met with him, seeking his wisdom. His influence also reached the British leadership, who recognized his potential to unite Muslims and even offered him honorary titles, which he humbly declined. Throughout his life, Pir Nazir Ahmed remained dedicated to the spiritual upliftment of Muslims and the pursuit of peace through the teachings of Islam.</p>


                                <div className="btns">
                                    <Link onClick={ClickHandler} to="/about" className="theme-btn" tabIndex="0">Discover More</Link>
                                    <ul>
                                        <li className="video-holder">
                                            <VideoModal/>
                                        </li>
                                        <li className="video-text">
                                            Watch Our Video
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="timer-section">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="timer-text">
                                <h2>Prayer Times</h2>
                                <p>Prayer times in Rawalpindi Pakistan</p>
                                <span>Mon 15 Jan, 2021</span>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="timer-num">
                                <ul>
                                    <li>Fajr<span>05:47</span></li>
                                    <li>Sunrize<span>07:05</span></li>
                                    <li>Dhuhr<span>12:34</span></li>
                                    <li>Asr<span>15:35</span></li>
                                    <li>Maghrib<span>17:58</span></li>
                                    <li>Isha'a<span>19:15</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="timer-shape1">
                        <img src={tmr1} alt="" />
                    </div>
                    <div className="timer-shape2">
                        <img src={tmr2} alt="" />
                    </div>
                    <div className="timer-shape3">
                        <img src={tmr3} alt="" />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default About2;