import React from 'react'
import {Link} from 'react-router-dom'
import tmr1 from '../../images/prayer-shape/2.png'
import tmr2 from '../../images/prayer-shape/1.png'
import mujtaba from '../../images/mujtaba.png'
import VideoModal from '../ModalVideo'
import './style.css'

const About5 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="wpo-about-img-3">
                                <img src={mujtaba} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <span>Pir Mujtaba Farooq Gul Badshah</span>
                                    <h2>Pir Mujtaba Farooq Gul Badshah</h2>
                                </div>
                                <p>At the age of 22, in 2007, he became the 5th successor at Darbar Mohra Sharif, a role that required balancing his duties in overseeing religious activities, public engagements, and social welfare initiatives while pursuing a degree in Computer Science from Fast University Islamabad. Despite facing significant challenges, he was guided by his father's encouragement and successfully completed his studies.</p>

                                <p>In 2009, he founded "Al Farooq Islamic And Modern Studies," an educational institution that has grown to over 25 branches, providing free education to students across Pakistan and Kashmir. Throughout his tenure, he has actively participated in relief efforts during natural disasters, including floods and the COVID-19 pandemic, collaborating with international NGOs to support over 15,000 families with essential supplies. He also organized awareness campaigns during the pandemic to inform and guide the local community, along with ongoing Polio campaigns to build trust and awareness.</p>

                                <p>Recently, he established the NGO "Helping First Welfare," focusing on welfare and awareness projects in Punjab, Sindh, and Kashmir. He has delivered over 1,000 lectures across various platforms, covering topics such as Sufism, peace, education, women's empowerment, and interfaith harmony. His work has garnered a significant following, attracting visits from political and religious leaders seeking guidance and support.</p>

                                <p>As the custodian of Darbar Mohra Sharif, he oversees two annual Sufi Conferences and various monthly and weekly gatherings aimed at promoting health, education, and conflict resolution. During Ramadan, he hosts daily Iftar gatherings, including an Interfaith Iftar Conference to foster unity among different faiths. His commitment extends beyond the shrine’s sanctity to creating an environment that encourages inner peace and spiritual growth, promoting unity and rejecting hatred and division in society.</p>


                                <div className="btns">
                                    <Link onClick={ClickHandler} to="/about5" className="theme-btn" tabIndex="0">Discover More</Link>
                                    <ul>
                                        <li className="video-holder">
                                            <VideoModal/>
                                        </li>
                                        <li className="video-text">
                                            Watch Our Video
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="timer-section">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="timer-text">
                                <h2>Prayer Times</h2>
                                <p>Prayer times in Rawalpindi Pakistan</p>
                                <span>Mon 15 Jan, 2021</span>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="timer-num">
                                <ul>
                                    <li>Fajr<span>05:47</span></li>
                                    <li>Sunrize<span>07:05</span></li>
                                    <li>Dhuhr<span>12:34</span></li>
                                    <li>Asr<span>15:35</span></li>
                                    <li>Maghrib<span>17:58</span></li>
                                    <li>Isha'a<span>19:15</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="timer-shape1">
                        <img src={tmr1} alt="" />
                    </div>
                    <div className="timer-shape2">
                        <img src={tmr2} alt="" />
                    </div>
                    <div className="timer-shape3">
                        <img src={tmr3} alt="" />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default About5;