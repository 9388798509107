import React from 'react'
import {Link} from 'react-router-dom'
import tmr1 from '../../images/prayer-shape/2.png'
import tmr2 from '../../images/prayer-shape/1.png'
import tmr3 from '../../images/prayer-shape/3.png'
import umer from '../../images/umer.jpg'
import VideoModal from '../ModalVideo'
import './style.css'

const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="wpo-about-img-3">
                                <img src={umer} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <span>About Us</span>
                                    <h2>Sahibzada Omer Farooq Gul</h2>
                                </div>
                                <p>He is a member of the prominent Spiritual Centre affiliated with the Naqshbandi order, known as Mohra Sharif, which has a rich history spanning two centuries. This institution played a significant role during the pre-independence era, with the Shaykhs of Mohra Sharif contributing to stability, conflict mediation, and fostering peaceful coexistence between Muslims and Christians in the region. This mission continues to be upheld today.</p>

                                <p>After completing his MS in Engineering Management from the University of Wollongong in Australia, he returned to Mohra Sharif to dedicate himself to serving humanity. He established a college in Rawalpindi, which has since become a beacon of education and empowerment for the local community. His commitment to education led to the founding of multiple institutions in Rawalpindi, offering both Islamic and contemporary education to over 2,000 students, thus providing pathways for academic and personal development.</p>

                                <p>In addition to his educational initiatives, he founded the Helping First Welfare organization, which is dedicated to supporting the underprivileged in various aspects of life. This welfare initiative focuses on providing assistance in food, education, and healthcare, ensuring that individuals in need receive the essential support required for a dignified and fulfilling life.</p>

                                <p>He has delivered lectures on diverse topics across numerous platforms, including conferences, seminars, universities, mosques, and prominent television channels in Pakistan. His discussions cover themes such as Sufism, peace and tolerance in Islam, interfaith harmony, Iqbal's political philosophy, and the character building of youth to become responsible citizens of Pakistan.</p>
                                <div className="btns">
                                    <Link onClick={ClickHandler} to="/about6" className="theme-btn" tabIndex="0">Discover More</Link>
                                    <ul>
                                        <li className="video-holder">
                                            <VideoModal/>
                                        </li>
                                        <li className="video-text">
                                            Watch Our Video
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="timer-section">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="timer-text">
                                <h2>Prayer Times</h2>
                                <p>Prayer times in Rawalpindi Pakistan</p>
                                <span>Mon 15 Jan, 2021</span>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="timer-num">
                                <ul>
                                    <li>Fajr<span>05:47</span></li>
                                    <li>Sunrize<span>07:05</span></li>
                                    <li>Dhuhr<span>12:34</span></li>
                                    <li>Asr<span>15:35</span></li>
                                    <li>Maghrib<span>17:58</span></li>
                                    <li>Isha'a<span>19:15</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="timer-shape1">
                        <img src={tmr1} alt="" />
                    </div>
                    <div className="timer-shape2">
                        <img src={tmr2} alt="" />
                    </div>
                    <div className="timer-shape3">
                        <img src={tmr3} alt="" />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default About;