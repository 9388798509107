import React from 'react'
import {Link} from 'react-router-dom'
import tmr1 from '../../images/prayer-shape/2.png'
import tmr2 from '../../images/prayer-shape/1.png'
import tmr3 from '../../images/prayer-shape/3.png'
import gul from '../../images/gul.png'

import VideoModal from '../ModalVideo'
import './style.css'

const About3 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="wpo-about-img-3">
                                <img src={gul} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <span>Hazrat Pir Gul Badshah</span>
                                    <h2>Hazrat Pir Gul Badshah</h2>
                                </div>
                                <p>Pir Muhammad Gul Badshah was born in 1917, and his birth was met with much anticipation and hope, particularly from his grandfather, Pir Khawaja Qasim. Pir Qasim named him and made special prayers for him to follow in his spiritual footsteps, guiding people towards the teachings of Prophet Muhammad (P.B.U.H). After his birth, Pir Gul Badshah became ill, and his father, Pir Nazir Ahmed, made a vow to sacrifice camels for his recovery. Pir Qasim reassured him, predicting that Gul Badshah would become a great spiritual leader who would illuminate hearts with the remembrance of Allah. His early education was closely supervised by his grandfather, who saw in him a successor and bestowed upon him the title of Khalifa, preparing him for his future role as the custodian of Darbar Mohra Sharif.</p>

                                <p>Pir Muhammad Gul Badshah's leadership extended beyond spiritual guidance, as he played a key role in managing the Darbar’s financial affairs. He contributed significantly to the Darbar's expenses, including its charitable langar services. His main source of income was through the wool trade, where he sourced wool from Peshawar, processed it, and sold it in Karachi for export. Later, he expanded his business ventures by entering the salt trade, which further supported the Darbar’s activities. His father, Pir Nazir Ahmed, recognized Gul Badshah’s dedication, attributing the success of Mohra Sharif Darbar to his hard work and leadership. Gul Badshah’s efforts in business were seen as an extension of his spiritual duties, ensuring the community's sustenance and continuity.</p>

                                <p>In 1968, Pir Muhammad Gul Badshah initiated a flour mill project aimed at creating employment opportunities and contributing to Pakistan’s economic growth. Although the government initially supported the project by granting a quota, political instability led to the revocation of all quotas, and the plan was never executed. Nevertheless, his commitment to the well-being of his followers remained unwavering. He implemented various initiatives, such as distributing cows to needy families, which helped them earn a livelihood through milk sales. This charitable system grew over time, benefiting hundreds of families across different regions. His actions were a testament to his deep compassion and concern for the prosperity of his community.</p>

                                <p>Pir Gul Badshah also took significant steps to support his followers' economic independence. For those with limited education, he encouraged learning practical skills, such as driving, and provided cars on installment plans, helping many enter the taxi business. For the educated, he offered guidance and facilitated careers in government positions or abroad. His approach was always rooted in promoting self-sufficiency and lawful earnings, reflecting his belief in the importance of Rizq e Halal (lawful income). These initiatives helped improve the lives of many, demonstrating his leadership beyond the spiritual realm.</p>

                                <p>Pir Muhammad Gul Badshah’s spiritual influence was further solidified through the Dastar Bandi ceremony, where his father officially declared him the successor of Mohra Sharif. This public acknowledgment ensured the continuation of the family’s spiritual legacy, with Pir Gul Badshah taking on the responsibility of guiding followers on the path of Sufism. His advice to the government of Pakistan also left a lasting impact. He suggested adding "Muhammad-ur-Rasool Allah" to the national slogan, emphasizing the completeness of Islamic faith. His advocacy for translating Quranic verses and Hadith before the news on national television also contributed to a deeper understanding of Islam among the public. Throughout his life, Pir Gul Badshah remained a devoted Sufi, leading his community in Dhikr (remembrance of Allah) and fostering spiritual growth among his followers.</p>

                                <p>Prominent leaders, including Field Marshal Sir Claude John Eyre Auchinleck, Viceroy Lord Archibald Wavell, and several Governors of Punjab and N.W.F.P., met with His Highness Al Hajj Pir Muhammad Gul Badshah, reflecting his significant influence in political and spiritual circles. Field Marshal Ayub Khan frequently sought his counsel on policy matters, with some of his advice shaping the Pakistani constitution. Notable visits also came from figures like Khawaja Nazimuddin and President General Zia Ul Haq, illustrating the deep respect and reliance placed on Pir Muhammad Gul Badshah by various leaders throughout his lifetime.</p>

                                <p>In his final days, Pir Muhammad Gul Badshah conveyed profound spiritual insights to his close associates, acknowledging his imminent passing while expressing peace in leaving behind his sons. He emphasized unity and brotherhood, urging them not to dispute succession as seen in other lineages. His last messages encouraged his followers to remain steadfast in their mission and uphold the values of love and sacrifice for the teachings of Rasool Allah. Following his death on August 15, 1992, his legacy of simplicity and service endures, as thousands continue to visit his resting place at Darbar Mohra Sharif, seeking spiritual guidance and inspiration from his life and teachings.</p>

                                <div className="btns">
                                    <Link onClick={ClickHandler} to="/about3" className="theme-btn" tabIndex="0">Discover More</Link>
                                    <ul>
                                        <li className="video-holder">
                                            <VideoModal/>
                                        </li>
                                        <li className="video-text">
                                            Watch Our Video
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="timer-section">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="timer-text">
                                <h2>Prayer Times</h2>
                                <p>Prayer times in Rawalpindi Pakistan</p>
                                <span>Mon 15 Jan, 2021</span>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="timer-num">
                                <ul>
                                    <li>Fajr<span>05:47</span></li>
                                    <li>Sunrize<span>07:05</span></li>
                                    <li>Dhuhr<span>12:34</span></li>
                                    <li>Asr<span>15:35</span></li>
                                    <li>Maghrib<span>17:58</span></li>
                                    <li>Isha'a<span>19:15</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="timer-shape1">
                        <img src={tmr1} alt="" />
                    </div>
                    <div className="timer-shape2">
                        <img src={tmr2} alt="" />
                    </div>
                    <div className="timer-shape3">
                        <img src={tmr3} alt="" />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default About3;