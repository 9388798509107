import React from 'react'
import { Link } from 'react-router-dom'
import sponser from '../../images/service/quran1.jpg'
import './style.css'

const ServiceChildSponsors = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-md-12 col-12">
                        <div className="service-single-content">
                            <div className="service-single-img">
                                <img src={sponser} alt=""/>
                            </div>
                            <h2>Child Sponsors</h2>
                            <p>Mohra Sharif, a well-regarded charity organization based in Rawalpindi, extends its heartfelt support to children in need through a dedicated child sponsorship program. This initiative is designed to provide comprehensive care for over 50 children, ensuring their essential needs are met. The program covers all aspects of the children's lives, including clothing, education, residence, and daily meals. By sponsoring a child at Mohra Sharif, donors can make a tangible difference in a child's life, offering them hope, stability, and the opportunity to pursue a brighter future.</p>
                            <p>The children sponsored through Mohra Sharif receive much more than just financial aid; they are welcomed into a nurturing environment that prioritizes their physical, emotional, and educational well-being. Each child is given a safe place to live, nutritious meals, quality education, and clothing, all of which contribute to their development and growth. Sponsorship provides these children with the opportunity to attend school, learn valuable life skills, and engage in activities that build their confidence and self-esteem.</p>
                            <div className="service-single-tb">
                                <div className="service-single-inner">
                                    {/* <span><img src={service2} alt=""/></span> */}
                                    <div className="service-single-tb-text">
                                        <p>Running such a comprehensive child sponsorship program requires substantial resources and ongoing support. Mohra Sharif continuously reaches out to compassionate individuals and organizations who can help sustain this life-changing work. Sponsorship funds are utilized to cover everyday expenses and ensure that the children have access to educational opportunities and healthcare services. Community support is vital in helping Mohra Sharif maintain the high standards of care and attention that each child receives.</p>
                                        <p>By sponsoring a child at Mohra Sharif, donors become a part of a child's journey toward a better life. The organization encourages more people to join this noble cause, emphasizing that every contribution, no matter how small, makes a significant impact. With the generosity and kindness of sponsors, Mohra Sharif can continue its mission of transforming the lives of vulnerable children, giving them the love, care, and support they need to build a successful and happy future.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="service-single-tb-content">
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.</p>
                            </div> */}
                        </div>
                        <div className="prv-nx">
                            <div className="pre-btn"><Link onClick={ClickHandler} to="/service-feeding-the-needy">Previous</Link></div>
                            <div className="nex-btn"><Link onClick={ClickHandler} to="/service-animal-sacrifice">Next</Link></div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-12 col-12">
                        <div className="service-sidebar">
                            <div className="widget service-list-widget">
                                <h3>All Services</h3>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/service-quran-memorization">Quran Memorization</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-clean-water-bores">Clean Water Bores</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-masjid-development">Masjid Development</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-feeding-the-needy">Feeding The Needy</Link></li>
                                    <li className="current"><Link onClick={ClickHandler} to="/service-child-sponsors">Child Sponsors</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-animal-sacrifice">Animal Sacrifice</Link></li>
                                </ul>
                            </div>
                            <div className="widget contact-widget">
                                <div>
                                    <h4>Request a Call Back</h4>
                                    <h2>(+92) 300-5264409</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceChildSponsors;
